<!-- /////////////////////////////////////////////////////////////////////////// TEMPLATE -->

<template>
    <div class="cs-sider cs-article-form text-gray-500">
        <!-- Header -->
        <header class="cs-sider__header">
            <div>
                Edit Article Properties
            </div>

            <div class="flex-grow"></div>

            <div v-if="loading">
                <img src="/img/loader-5.gif" alt="..." class="inline w-4 h-4">
            </div>

            <div v-else-if="isDirty" class="cursor-default text-4xl" title="Unsaved changes">
                &bull;
            </div>
        </header>

        <!-- Form -->
        <div class="cs-sider__content p-4">
            <!-- Availability Communication -->
            <lsn-select
                v-model="availabilityCommunication"
                class="w-1/2 lsn-form__wrapper--padless mb-4"
                label="Availability Communication"
                ckey="label"
                :options="availabilityCommunications"
                ovalue="code"
                :error="errors.availability_communication"
            />

            <!-- Box Size -->
            <lsn-input-number
                v-model="boxSize"
                class="lsn-form__wrapper--padless mb-4"
                label="Box Size"
                ckey="box_size"
                :min="1"
                :error="errors.box_size"
            />

            <!-- General Communication -->
            <lsn-input
                v-model="generalCommunication"
                class="w-1/2 lsn-form__wrapper--padless mb-4"
                label="General Communication"
                :error="errors.general_communication"
            />

            <!-- Customs Price CHF -->
            <lsn-input-number
                v-model="customsPriceChf"
                class="lsn-form__wrapper--padless mb-4"
                label="Customs Price CHF"
                ckey="customs.price.CHF"
                :min="0"
                :step="0.01"
                :error="errors['customs.price.CHF']"
            />

            <!-- Customs Price EUR -->
            <lsn-input-number
                v-model="customsPriceEur"
                class="lsn-form__wrapper--padless mb-4"
                label="Customs Price EUR"
                ckey="customs.price.EUR"
                :min="0"
                :step="0.01"
                :error="errors['customs.price.CHF']"
            />

            <!-- Customs Price PLN -->
            <lsn-input-number
                v-model="customsPricePln"
                class="lsn-form__wrapper--padless mb-4"
                label="Customs Price PLN"
                ckey="customs.price.PLN"
                :min="0"
                :step="0.01"
                :error="errors['customs.price.PLN']"
            />

            <!-- Customs Price USD -->
            <lsn-input-number
                v-model="customsPriceUsd"
                class="lsn-form__wrapper--padless mb-4"
                label="Customs Price USD"
                ckey="customs.price.USD"
                :min="0"
                :step="0.01"
                :error="errors['customs.price.USD']"
            />

            <!-- Customs Price UAH -->
            <lsn-input-number
                v-model="customsPriceUah"
                class="lsn-form__wrapper--padless mb-4"
                label="Customs Price UAH"
                ckey="customs.price.UAH"
                :min="0"
                :step="0.01"
                :error="errors['customs.price.UAH']"
            />

            <!-- Gross Weight -->
            <lsn-input-number
                v-model="grossWeight"
                class="lsn-form__wrapper--padless mb-4"
                label="Gross Weight"
                ckey="gross_weight"
                :min="0"
                :step="0.001"
                :error="errors.gross_weight"
            />

            <!-- Net Weight -->
            <lsn-input-number
                v-model="netWeight"
                class="lsn-form__wrapper--padless mb-4"
                label="Net Weight"
                ckey="net_weight"
                :min="0"
                :step="0.001"
                :error="errors.net_weight"
            />

            <!-- Sort Order -->
            <lsn-input-number
                v-model="sortOrder"
                class="lsn-form__wrapper--padless mb-4"
                label="Sort Order"
                ckey="sort_order"
                :min="0"
                :error="errors.sort_order"
            />

            <!-- Shipping Checklist Label -->
            <lsn-input
                v-model="shippingChecklistLabel"
                class="lsn-form__wrapper--padless mb-4"
                label="Shipping Checklist Label"
                ckey="shipping_checklist_label"
                :error="errors.shipping_checklist_label"
            />

            <!-- SKU -->
            <lsn-input
                v-model="sku"
                class="lsn-form__wrapper--padless mb-4"
                label="SKU"
                ckey="sku"
                :error="errors.sku"
            />

            <!-- Display Group -->
            <lsn-input
                v-model="displayGroup"
                class="lsn-form__wrapper--padless mb-4"
                label="Display Group"
                ckey="display_group"
                :error="errors.display_group"
            />

            <!-- Unit -->
            <lsn-input-number
                v-model="unit"
                class="lsn-form__wrapper--padless mb-4"
                label="Unit"
                ckey="unit"
                :min="1"
                :error="errors.unit"
            />

            <!-- Error message, if any -->
            <p v-if="errorMessage" class="lsn-form__error mb-4">
                {{ errorMessage }}
            </p>
        </div>

        <div class="cs-sider__actions">
            <div class="lsn-field-wrapper">
                <div
                    class="lsn-btn lsn-btn--primary-outline"
                    tabindex="0"
                    @click="cancel"
                    @keyup.enter="cancel"
                    @keyup.space="cancel"
                >
                    Cancel
                </div>
            </div>

            <div class="lsn-field-wrapper ml-3">
                <div
                    class="lsn-btn"
                    :class="isDirty && !loading
                        ? 'lsn-btn--primary'
                        : 'lsn-btn--disabled'"
                    tabindex="0"
                    @click="save"
                    @keyup.enter="save"
                    @keyup.space="save"
                >
                    Save
                </div>
            </div>
        </div>
    </div>
</template>


<!-- /////////////////////////////////////////////////////////////////////////// SCRIPT -->

<script>
import axios from '@/axios';

export default
{
    name: 'ArticlePropertiesForm',

    data()
    {
        return {
            // Article properties
            availabilityCommunication: '',
            boxSize:                   null,
            generalCommunication:      '',
            customsPriceChf:           null,
            customsPriceEur:           null,
            customsPricePln:           null,
            customsPriceUsd:           null,
            customsPriceUah:           null,
            grossWeight:               null,
            netWeight:                 null,
            shippingChecklistLabel:    null,
            sku:                       null,
            displayGroup:              null,
            sortOrder:                 null,
            unit:                      null,

            // Component-specific attributes
            loading:      false,
            errorMessage: '',
            errors:       {},
        };
    },

    computed:
    {
        /**
         * The article of reference in the store.
         */
        refArticle()
        {
            return this.$store.state.articles.cArticle;
        },

        availabilityCommunications()
        {
            return [
                {
                    label: '<None>',
                    code:  '',
                },
                {
                    label: 'Contact Customer Service',
                    code:  'availability_communication.contact_customer_service',
                },
                {
                    label: 'Out of Stock',
                    code:  'availability_communication.out_of_stock',
                },
            ];
        },

        isDirty()
        {
            // Prepare currently selected field values
            const currentValues = {
                availability_communication: this.availabilityCommunication || null,
                box_size:                   this.boxSize                   || null,
                general_communication:      this.generalCommunication      || null,
                'customs.price.CHF':        this.customsPriceChf           || null,
                'customs.price.EUR':        this.customsPriceEur           || null,
                'customs.price.PLN':        this.customsPricePln           || null,
                'customs.price.USD':        this.customsPriceUsd           || null,
                'customs.price.UAH':        this.customsPriceUah           || null,
                gross_weight:               this.grossWeight               || null,
                net_weight:                 this.netWeight                 || null,
                shipping_checklist_label:   this.shippingChecklistLabel    || null,
                sku:                        this.sku                       || null,
                display_group:              this.displayGroup              || null,
                sort_order:                 this.sortOrder                 || null,
                unit:                       this.unit                      || null,
            };

            // Prepare field values from the database
            const referenceValues = {
                availability_communication: this.getRefValue('availability_communication') || null,
                box_size:                   this.getRefValue('box_size')                   || null,
                general_communication:      this.getRefValue('general_communication')      || null,
                'customs.price.CHF':        this.getRefValue('customs.price.CHF')          || null,
                'customs.price.EUR':        this.getRefValue('customs.price.EUR')          || null,
                'customs.price.PLN':        this.getRefValue('customs.price.PLN')          || null,
                'customs.price.USD':        this.getRefValue('customs.price.USD')          || null,
                'customs.price.UAH':        this.getRefValue('customs.price.UAH')          || null,
                gross_weight:               this.getRefValue('gross_weight')               || null,
                net_weight:                 this.getRefValue('net_weight')                 || null,
                shipping_checklist_label:   this.getRefValue('shipping_checklist_label')   || null,
                sku:                        this.getRefValue('sku')                        || null,
                display_group:              this.getRefValue('display_group')              || null,
                sort_order:                 this.getRefValue('sort_order')                 || null,
                unit:                       this.getRefValue('unit')                       || null,
            };

            // If any property differs from the corresponding one in refArticle, mark the form as "dirty"
            return Object.keys(currentValues)
                .some(pkey => currentValues[pkey] !== referenceValues[pkey]);
        },
    },

    created()
    {
        this.initialize();
    },

    methods:
    {
        initialize()
        {
            // Availability Communication
            this.availabilityCommunication = this.getRefValue('availability_communication') || null;

            // Box Size
            this.boxSize = parseInt(this.getRefValue('box_size')) || null;

            // General Communication
            this.generalCommunication = this.getRefValue('general_communication') || null;

            // Customs Price CHF
            this.customsPriceChf = this.getRefValue('customs.price.CHF') || null;

            // Customs Price EUR
            this.customsPriceEur = this.getRefValue('customs.price.EUR') || null;

            // Customs Price PLN
            this.customsPricePln = this.getRefValue('customs.price.PLN') || null;

            // Customs Price USD
            this.customsPriceUsd = this.getRefValue('customs.price.USD') || null;

            // Customs Price UAH
            this.customsPriceUah = this.getRefValue('customs.price.UAH') || null;

            // Gross Weight
            this.grossWeight = parseFloat(this.getRefValue('gross_weight'));
            if(isNaN(this.grossWeight))
            {
                this.grossWeight = null;
            }

            // Net Weight
            this.netWeight = parseFloat(this.getRefValue('net_weight'));
            if(isNaN(this.netWeight))
            {
                this.netWeight = null;
            }

            // Shipping Checklist Label
            this.shippingChecklistLabel = this.getRefValue('shipping_checklist_label') || null;

            // SKU
            this.sku = this.getRefValue('sku') || null;

            // Display Group
            this.displayGroup = this.getRefValue('display_group') || null;

            // Sort Order
            this.sortOrder = parseInt(this.getRefValue('sort_order')) || null;

            // Unit
            this.unit = parseInt(this.getRefValue('unit')) || null;
        },

        cancel()
        {
            const article_id = parseInt(this.$route.params.article_id);

            if(article_id)
            {
                // Cancel editing an existing article -> back to viewing that article
                this.$router.push({ name: 'view-article', params: { article_id } });
            }
            else
            {
                // Cancel creating a new article -> back to the articles list
                this.$router.push({ name: 'articles' });
            }
        },

        save()
        {
            if(!this.isDirty || this.loading)
            {
                return;
            }

            this.loading = true;

            // Prepare request payload
            const payload = {
                availability_communication: this.availabilityCommunication,
                box_size:                   this.boxSize,
                general_communication:      this.generalCommunication,
                'customs.price.CHF':        this.customsPriceChf,
                'customs.price.EUR':        this.customsPriceEur,
                'customs.price.PLN':        this.customsPricePln,
                'customs.price.USD':        this.customsPriceUsd,
                'customs.price.UAH':        this.customsPriceUah,
                gross_weight:               this.grossWeight,
                net_weight:                 this.netWeight,
                shipping_checklist_label:   this.shippingChecklistLabel,
                sku:                        this.sku,
                display_group:              this.displayGroup,
                sort_order:                 this.sortOrder,
                unit:                       this.unit,
            };

            axios.put(`/api/article/${this.$route.params.article_id}/properties`, payload)
                .then(({ data: article }) =>
                {
                    this.errorMessage = '';
                    this.errors = {};

                    // Update the current article in the store
                    this.$store.dispatch('articles/updateInArticlesList', article);
                    this.$store.dispatch('articles/setCurrentArticle', article);

                    // Redirect to view the created/updated article
                    this.$router.push({ name: 'view-article', params: { article_id: article.id } });
                })
                .catch(error =>
                {
                    if(import.meta.env.MODE !== 'production')
                    {
                        console.log('Article Properties Form Error', error.toJSON?.() || error);
                        console.log('Article Properties Form Error Response', error.response);
                    }

                    // Set general error message
                    if(error.response?.message)
                    {
                        this.errorMessage = error.response.message;
                    }
                    else if(error.message)
                    {
                        this.errorMessage = error.message;
                    }
                    else if(typeof error === 'string')
                    {
                        this.errorMessage = error;
                    }

                    // Set field-specific errors
                    if(error.response?.errors)
                    {
                        this.errors = error.response.errors;
                    }
                })
                .then(() =>
                {
                    this.loading = false;
                });
        },

        getRefValue(pkey)
        {
            return this.refArticle.extra?.find(p => p.pkey === pkey)?.pvalue;
        },
    },
};
</script>


<!-- /////////////////////////////////////////////////////////////////////////// STYLE -->

<style lang="scss" scoped>
</style>
